import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ButtonBlack from 'components/button/button-black'
import ContactForm from 'components/contact-form'
import RootCanalGif from '../../../content/images/root-canal-new-img.gif'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'
import Video from 'components/video-embed'

interface Props {
  location: Location
}

const RootCanalPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section2_placeholder = data.section2_placeholder?.childImageSharp?.fluid
  const section3_left_img = data.section3_left_img?.childImageSharp?.fluid
  const contact_background = data.contact_background?.childImageSharp?.fluid
  const section_background = data.section_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Root Canal Therapy Indianapolis IN</title>
        <meta name="description" content="Indianapolis IN Endodontist Dr. Broady Root canals are one of the most common dental procedures performed. Call our Indianapolis IN office at 317-846-4980 for more information" />
        <link rel="canonical" href="https://meridianendoindy.com/procedures/root-canal-therapy/" />
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Root Canal Therapy Indianapolis IN" />
        <meta name="og:description" content="Indianapolis IN Endodontist Dr. Broady Root canals are one of the most common dental procedures performed. Call our Indianapolis IN office at 317-846-4980 for more information" />
        <meta property="og:url" content="https://meridianendoindy.com/procedures/root-canal-therapy/" />
        <meta property="og:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Root Canal Therapy Indianapolis IN" />
        <meta name="twitter:description" content="Indianapolis IN Endodontist Dr. Broady Root canals are one of the most common dental procedures performed. Call our Indianapolis IN office at 317-846-4980 for more information" />
        <meta name="twitter:url" content="https://meridianendoindy.com/procedures/root-canal-therapy/" />
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279" />
        <meta property="og:image:height" content="65" />
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="root-canal-hero-section-bg hero-bg"
        fluid={hero_background}
        backgroundColor={`#ffffff`}
        alt="Endodontics pc"
      >
        <div id="root-canal-hero-section">
          <Container>
            <div className="col-lg-6 hero-left">
              <h1 className="hero-heading primary-text">
                Root Canal Therapy
              </h1>
              <p className="hero-text">
                <strong className="text-black">What is a root canal?</strong><br/>
                A root canal is one of the most common dental procedures performed, with well over 14 million occurring every year.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <BackgroundImage
        Tag="section"
        className="root-canal-section1-2-bg"
        fluid={section_background}
        alt="Endodontics pc"
      >
      <section id="root-canal-section-1">
        <Container>
            <div className="col-lg-12 root-canal-container">
              <div className="row">
                <div className="col-lg-8">
                  <h4>What is a Root Canal?</h4>
                  <p>
                    Root canal treatment is designed to eliminate bacteria from the infected root canal, prevent reinfection of the tooth and save the natural tooth. When one undergoes a root canal, the inflamed or infected pulp is removed and the inside of the tooth is carefully cleaned and disinfected, then filled and sealed.
                  </p>
                  <p>
                    There’s no need to be worried if your dentist or endodontist prescribes a root canal procedure to treat a damaged or diseased tooth. Millions of teeth are treated and saved this way each year, relieving pain and making teeth healthy again.
                  </p>
                </div>
                <div className="col-lg-4">
                <img src={RootCanalGif} className="root-canal-gif"/>
                {/* <iframe className="section1-video" src="https://fast.wistia.net/embed/iframe/agp6zxlz0w"></iframe> */}
                {/*}  <p className="text-center click-here"><a href="https://fast.wistia.net/embed/iframe/agp6zxlz0w" target="_blank">Click here for high-res version</a></p> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p className="row2-p">
                    Inside your tooth, beneath the white enamel and a hard layer called dentin, is a soft tissue called pulp. This tissue contains blood vessels, nerves and connective tissue, which help grow the root of your tooth during its development. A fully developed tooth can survive without the pulp because the tooth continues to be nourished by the tissues surrounding it.
                  </p>
                  <p>
                    A modern root canal is nothing like those old sayings! It’s very similar to a routine filling and can usually be completed in one or two appointments, depending on the condition of your tooth and your personal circumstances. Getting a root canal is relatively painless and extremely effective. You’ll be back to smiling, biting and chewing with ease in no time.
                  </p>
                  <p>
                    The success rate for endodontic treatment is about 90%. If your tooth cannot be treated with this type of procedure or the chance of a successful outcome is low, you will learn it at the time of consultation.
                  </p>
                  <p>
                    During the procedure, we use local anesthesia.  Additionally, our care team provides nitrous oxide and oral sedation if needed. Patients that undergo the procedure with only local anesthetic or nitrous oxide can drive themselves home and go about the rest of their regular routine. If we use oral sedation, you will need a responsible driver or alternative way home.
                  </p>
                  <p>
                    We recommend oral sedation for patients with severe fear or anxiety of dental procedures. For proper treatment you need to be relaxed and comfortable during the procedure. We can provide oral sedation medication before the treatment to keep you comfortable. The medication prevents patients from remembering most of the treatment. A consultation appointment is required before the doctor approves oral sedation. <strong>For more information, contact Meridian Endodontics at <a href="tel:3178464980" id="tel-link">317-846-4980.</a></strong>
                  </p>
                </div>
              </div>
          </div>
        </Container>
      </section>

      {/*}<section id="root-canal-section-2">
        <Container>
          <div className="col-lg-12 root-canal-container">
            <div className="row section2-content">
              <div className="col-lg-7">
                <h2>Endodontic Presentation</h2>
                <p>To help you understand what endodontic therapy is and how the procedure works, we've created a presentation to take you through each step. In addition, you can find the answer to many questions by reviewing the content below.</p>
              </div>
              <div className="col-lg-5">
              <Video videoSrcURL="https://fast.wistia.com/embed/medias/xae56qd8y6" videoThumbSrc={section2_placeholder} className="section1-video" />
                {/*<Img fluid={section2_placeholder} className="section2-img" />*/}
        {/*}      </div>
            </div>
          </div>
        </Container>
      </section> */}
      </BackgroundImage>
      <section id="root-canal-section-3">
        <Container>
          <div className="col-lg-12 root-canal-container">
            <div className="row section2-content">
              <div className="col-lg-3">

                <Img fluid={section3_left_img} className="section3-img" />
              </div>
              <div className="col-lg-9">
                <h3>What happens after treatment?</h3>
                <p>
                  When the procedure is complete, we send a full record to your restorative dentist. To ensure you get an appointment quickly for a permanent cover for your tooth, contact their office as soon as possible. The restorative dentist will review our treatment outcome and determine the best way to protect your tooth from further infection or trauma.
                </p>
                <p>
                  Although problems following a root canal are rare, sometimes the patient will have pain a few months or years after the initial treatment. If your pain doesn't go away or comes back, contact our office as soon as possible. We have an answering service available 24/7 to handle all emergencies. To prevent further decay, continue to practice good dental hygiene.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <h3 className="row2-title">How much will it cost?</h3>
              <p>There's no set price for each patient. However, most consider the price of a root canal lower than losing a tooth and having an artificial replacement like a bridge or a dental implant. <strong>Please contact our office at <a href="tel:317-846-4980" id="tel-link">317-846-4980.</a> with any questions.</strong></p>
            </div>
          </div>
        </Container>
      </section>

      <section id="root-canal-section-4">
        <Container>
          <div className="col-lg-12 section4-video">
            <iframe className="youtube-video" src="https://www.youtube.com/embed/JLeiDmOVfcg"></iframe>
            </div>
        </Container>
      </section>
      <BackgroundImage
        Tag="section"
        className="root-canal-contact-section-bg"
        fluid={contact_background}
        alt="Endodontics pc"
      >
        <section id="root-canal-section-5">
          <ContactForm/>
        </section>
      </BackgroundImage>
    </Layout>
  )
}

export default RootCanalPage

export const query = graphql`
  query RootCanalPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "root-canal-hero" }) {
      childImageSharp {
        fluid(maxWidth: 520, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_placeholder: file(name: { eq: "section2-placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section3_left_img: file(name: { eq: "section3-left-img" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    contact_background: file(name: { eq: "root-canal-contact-bg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section_background: file(name: { eq: "root-canal-bg-2" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
